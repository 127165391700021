import React from "react"
import './CarouselDoublePhoto.css'

type CarouselDoublePhotoProps = {
	source1: string;
	source2: string;
}

function CarouselDoublePhoto(props: CarouselDoublePhotoProps) {
  return <>
    <div className="d-flex flex-column double-photo p-1 justify-content-between">
      <div className="photo m-0 d-flex justify-content-center">
        <img className="d-block top-0" src={
            require(`../assets/fotos/${props.source1}`)
          }
        />
      </div>
      <div className="photo m-0 d-flex justify-content-center">
        <img className="d-block bottom-0" src={
            require(`../assets/fotos/${props.source2}`)
          }
        />
      </div>
    </div>
  </>
}

export default CarouselDoublePhoto