import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from '../assets/Logo_Ver_Comp.svg'
import './Footer.css'


function Footer() {
	return (
		<footer className="footer">
			<Container>
				<Row className="pb-5 pb-md-0">	
					<Col md={4} className="d-flex justify-content-center justify-content-md-end">
							<div className="d-flex my-1 mx-3 flex-column align-items-center">
								<img id="footer-logo" className="img-fluid m-3" src={logo}/>
							</div>
							<div id="footer-divider" className="mx-1 my-4 d-none d-md-block">
								<div id="divider-end-top" className="d-none d-md-block divider-end"/>
								<div id="divider-end-bottom" className="d-none d-md-block divider-end"/>
							</div>
					</Col>
					<Col md={4} className="d-flex  my-4 my-md-auto  justify-content-center">
						<div id="hours" className="d-inline-block text-center">
							<h3>Horário de Funcionamento</h3>
							<h4><b>Segunda a sábado:</b> <br/> 09:00 ás 21:00</h4>
						</div>
					</Col>
					<Col md={4} className="d-flex align-items-center justify-content-center">
						<div id="contact-info">
							<h3 className="text-center"><i className="fas fa-map-marker-alt"/> Rua Montevidéu, 27, Serrinha, Fortaleza-CE</h3>
							<h4 className="text-center"><i className="fas fa-phone fa-sm"/> (85) 98678-8269</h4>
							<h4 className="text-center"><i className="far fa-envelope"/> contato@lamelshopping.com.br</h4>
							<h4 className="text-center">
								<a href="https://www.instagram.com/lamelshopping/" target="_blank">
									<i className="fab fa-instagram"></i> lamelshopping
								</a>
							</h4>
						</div>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default Footer;