import React, { useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import Main from "../components/Main";
import videoInaug from '../assets/video_inaug.mp4'
import "./Home.css"
import photos from "../assets/fotos.json"
import ReactPlayer from "react-player";
import CarouselDoublePhoto from "../components/CarouselDoublePhoto";

function Home() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: React.SetStateAction<number>, e: any) => {
    setIndex(selectedIndex);
  };

	return (
		<>
			<Container fluid >
				<Row className="d-flex mx-2 mx-md-5 mt-3 shadow p-2" id="top-container">
					<Col md={8} className="d-flex my-2 allign-items-center">
						<div id="video-container" className="double-border w-100">
							{/* <iframe 
								width="420" 
								height="315"
								allow="autoplay"
								src="https://www.youtube.com/embed/tgbNymZ7vqY?muted=1&autoplay=1">
							</iframe> */}
							<ReactPlayer className="react-player p-2"
								url="https://youtu.be/qmGjt9HyE1I"
								loop={true}
								volume={0.0}
								controls={true}
								playing={true}
								width="100%"
								height="100%"
							/>
						</div>
					</Col>
					<Col md={4} className="d-flex my-2 justify-content-center my-auto">
						<Carousel 
							activeIndex={index}
							onSelect={handleSelect}
							className="double-border p-2"
							nextIcon={<i className="fas fa-chevron-right" aria-hidden="true"></i>}
							prevIcon={<i className="fas fa-chevron-left" aria-hidden="true"></i>}
						>
							{photos.map((photo, id) => (
									<Carousel.Item key={id}>
										<CarouselDoublePhoto source1={photo[0]} source2={photo[1]}/>
									</Carousel.Item>
								)
							)}
						</Carousel>
					</Col>
				</Row>
			</Container>

			<Main>
				<Container fluid>
					<hr/>
						<Row className="d-flex justify-content-center align-items-center" id="social">
							<div className="d-flex col-sm-6 justify-content-center justify-content-sm-end text-end">
								<h1>Siga nosso instagram</h1>
							</div>
							<div className="d-flex col-sm-6 justify-content-center justify-content-sm-start">
									<a href="https://www.instagram.com/lamelshopping/" target="_blank">
										<h1><i className="fab fa-instagram-square"></i> lamelshopping</h1>
									</a>
							</div>
						</Row>
					<hr/>
				</Container>
				<Container fluid>
				<iframe className="shadow"
					width="100%"
					height="450"
					loading="lazy"
					allowFullScreen
					src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ30MAZ1ZPxwcRWyTPBzAeXbE&key=AIzaSyCN0k46KCzpnp6oNCqSu_JTaPA8_JymECg">
				</iframe>
				</Container>
			</Main>
		</>
	)
}

export default Home;