import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Main from "../components/Main";
import "./Contact.css"

function Contact() {
	return (
		<Main>
			<Container fluid>
				<h1 className="ms-2 pt-4 px-3">Entre em contato</h1>
				<hr id="hr"/>
				<form id="contact-form" method="post" action="https://api.formcake.com/api/form/4a87eca7-8d75-4762-a94d-1cad92e6de7b/submission">
					<div className="form-group my-1">					
						<label className="form-label">
							Nome <br/>
						</label>
				    <input className="form-control" required name="name" type="text" placeholder="Seu nome"/>
					</div>
					<div className="form-group my-1">
		    		<label className="form-label">
							Email <br/>
						</label>
			    	<input className="form-control" required name="email" type="email" placeholder="seu@email.com"/>
					</div>
					<div className="form-group my-1">
		    		<label className="form-label">
							Telefone <br/>
						</label>
			    	<input className="form-control" required name="phone" type="tel" placeholder="(XX) XXXXXXXXX"/>
					</div>
					<div className="form-group my-1">
						<label className="form-label">
							Assunto <br/>
						</label>
			    	<input className="form-control" name="subject" type="text" placeholder="..."/>
					</div>
					<div className="form-group my-1">
						<label className="form-label">
							Mensagem <br/>
						</label>
			    	<input className="form-control" name="message" type="text" placeholder="..."/>
					</div>
					<div className="form-group my-1">
						<button className="btn fw-bold" type="submit">Enviar</button>
					</div>
				</form>
			</Container>
		</Main>
	)
}

export default Contact;