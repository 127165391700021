import React, { useState } from "react";
import './StoreCard.css'

type StoreCardProps = {
	name: string;
	storeType: string;
	phone: string;
	source: string;
}

function StoreCard(props: StoreCardProps) {
	return (
		<div className="shadow store-card m-0 p-0">
			<div className="card-image m-0 d-flex justify-content-center">
				<img src={
						require(`../assets/img-lojas/${props.source}`)
					}
				/>
			</div>
			<div className="card-info d-flex flex-column m-0 pb-1 ps-2 justify-content-between">
				<p className="fs-3">{props.name}</p>
				<p className="fs-6">{props.storeType}</p>
				<p className="fs-6"><i className="fas fa-phone fa-sm"></i>{props.phone}</p>
			</div>
		</div>
	)
}

export default StoreCard;