import React from "react";
import { Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Main from "../components/Main";
import "./ContactSucess.css"

function Contact() {
	return (
		<Main>
			<Container fluid>
				<h1 className="ms-2 pt-4 px-3">Entre em contato</h1>
				<hr id="hr"/>
				<p className="fs-2">Recebemos sua mensagem.</p>
				<LinkContainer to="/">
					<button id="back-button" className="btn fs-2">Voltar</button>
				</LinkContainer>
			</Container>
		</Main>
	)
}

export default Contact;