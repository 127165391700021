import React from "react";

type Props = {
  children: React.ReactNode;
};

function Main(props: Props) {
	return (
		<main className="mb-5 px-md-3 flex-grow-1">
			{props.children}
		</main>
	)
}

export default Main;