import React from "react";
import { Container, Nav, Navbar, NavItem } from "react-bootstrap";
import './NavigationBar.css'
import logo from '../assets/Logo_Hor_Comp.svg'
import { LinkContainer } from "react-router-bootstrap" 
import { Page } from "../models/Page";

type Props = {
	pages: Page[],
}

function NavigationBar(props: Props) {
	return (
		<Navbar collapseOnSelect expand="sm" className="fixed-top px-1 px-md-5 py-1 m-0">
			<Container fluid>
				<LinkContainer to="/" data-rr-ui-event-key="/">
					<Navbar.Brand>
						<img src={logo} height="50"/>
					</Navbar.Brand>
				</LinkContainer>
				<Navbar.Toggle aria-controls="navbarScroll">
					<i className="fas fa-bars fa-2x"></i>
				</Navbar.Toggle>
				<Navbar.Collapse className="justify-content-end">
					<Nav>
						{
							props.pages.map((page, id) =>{
									if (page.name != "-"){
										return (
											<LinkContainer key={id} to={page.path}>
												<Nav.Link className="rounded text-center m-2 p-2 fs-5">{page.name}</Nav.Link>
											</LinkContainer>
										)
									}
								}
							)
						}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

export default NavigationBar;