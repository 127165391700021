import React from 'react';
import './App.css';
import NavigationBar from './components/NavigationBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Stores from './pages/Stores';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import { Page } from './models/Page';
import ContactSucess from './pages/ContactSucess';

function App() {
	const pages: Page[] = [
		{
			element: Home(),
			path: "/",
			name: "Shopping",
		},
		{
			element: Stores(),
			path: "/lojas",
			name: "Lojas",
		},
		// {
		// 	element: Events(),
		// 	path: "/eventos",
		// 	name: "Eventos",
		// },
		{
			element: Contact(),
			path: "/contato",
			name: "Contato",
		},
		{
			element: ContactSucess(),
			path: "/contato/sucesso",
			name: "-",
		},
	]

  return (
    <React.Fragment>
			<NavigationBar pages={pages}/>
			
			<Routes>
				{
					pages.map((page, id) => (
							<Route key={id} path={page.path} element={page.element}/>
						)
					)
				}
			</Routes>

			<Footer/>
		</React.Fragment>
  );
}

export default App;
