import React from "react";
import StoreCard from "../components/StoreCard";
import Main from "../components/Main";
import stores from "../assets/lojas.json"
import { Col, Container, Row } from "react-bootstrap";
import "./Stores.css";

function Stores() {
	return (
		<Main>
			<Container fluid>				
				<h1 className="ms-2 pt-4 px-3">Nossas Lojas</h1>
				<hr id="hr"/>
				<Row className="pb-4 justify-content-center">
					{stores.map((store, id) => (
						<Col key={id} className="d-flex py-3 col-6 col-sm-4 col-md-3 justify-content-center">
								<StoreCard 
									name={store.nome}
									storeType={store.tipo}
									phone={store.telefone}
									source={store.imagem}
									/>
							</Col>
						)
					)}
				</Row>
			</Container>
		</Main>
	)
}

export default Stores;